import * as React from "react";
import styled from "styled-components";

import PostCategory from "./PostCategory";

const PostCategoriesStyles = styled.span`
  display: flex;
  gap: 1rem;
  color: var(--highlight-dark-theme);
`;

const PostCategories = ({ categories }) => (
  <PostCategoriesStyles>
    {categories.map((category) => (
      <PostCategory key={category._id} category={category} />
    ))}
  </PostCategoriesStyles>
);

export default PostCategories;

import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import "./styles.css";
import "./typography.css";

import { SmallHeader } from "../components/SmallHeader";
import PostMetaData from "../components/Post/PostMetaData";
import { Footer } from "../components/Footer";

const BlobPostStyles = styled.section`
  max-width: var(--narrow-width);
  margin: 95px auto 0 auto;
  padding: 0 1rem;

  .postHero {
    width: 100%;
    height: auto;
  }
`;

const blobPost = ({ data }) => {
  const { author, body, categories, mainImage, title, publishedAt } =
    data.sanityPost;
  const image =
    mainImage.asset.localFile.childImageSharp.gatsbyImageData.images.fallback;

  return (
    <BlobPostStyles>
      <SmallHeader />
      <h2>{title}</h2>
      <PostMetaData
        author={author}
        categories={categories}
        publishedAt={publishedAt}
      />
      <img
        className="postHero"
        srcSet={image.srcSet}
        sizes={image.sizes}
        src={image.src}
        alt={title}
      />
      {body.map((paragraph, index) => (
        <p key={index}>{paragraph.children[0].text}</p>
      ))}
      <Footer />
    </BlobPostStyles>
  );
};

export default blobPost;

export const query = graphql`
  query blobPostQuery($slug: String!) {
    sanityPost(slug: { current: { eq: $slug } }) {
      title
      mainImage {
        asset {
          localFile {
            childImageSharp {
              gatsbyImageData(sizes: "max-width: 400px 300px 200px")
            }
          }
        }
      }
      categories {
        title
      }
      body {
        children {
          text
        }
      }
      author {
        name
        image {
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(sizes: "max-width: 400px 300px 200px")
              }
            }
          }
        }
        bio {
          children {
            text
          }
        }
      }
      publishedAt(fromNow: true)
    }
  }
`;

import * as React from "react";
import styled from "styled-components";

import Author from "../Author";
import PostCategories from "./PostCategories";

const PostMetaDataStyles = styled.header`
  position: relative;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  & > * {
    position: relative;
    margin-right: 0.75rem !important;
    height: 40px;
  }
  & > *::after {
    position: absolute;
    content: " | ";
    font-size: 22px;
    top: 0;
    right: -1rem;
  }
  & > *:last-child::after {
    content: "";
  }

  p {
    display: flex;
    align-items: center;
    margin: 0;
  }

  @media (max-width: 600px) {
    & > * {
      position: relative;
      margin-right: 0.75rem;
      height: 40px;
    }

    & > *::after {
      content: "";
    }
  }
`;

const PostMetaData = ({ author, categories, publishedAt }) => (
  <PostMetaDataStyles>
    <Author author={author} />
    <p>Published: {publishedAt}</p>
    <p>
      Categories: &nbsp;
      <PostCategories categories={categories} />
    </p>{" "}
  </PostMetaDataStyles>
);

export default PostMetaData;

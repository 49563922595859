import * as React from "react";
import styled from "styled-components";

const AuthorStyles = styled.section`
  --size: 40px;

  display: flex;
  place-items: center;
  gap: 1rem;

  img {
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
  }
`;

const Author = ({ author }) => {
  const image =
    author.image.asset.localFile.childImageSharp.gatsbyImageData.images
      .sources[0];

  return (
    <AuthorStyles>
      <img
        srcSet={image.srcSet}
        sizes={image.sizes}
        src={image.src}
        alt={author.image.asset.altText}
      />
      <p>{author.name}</p>
    </AuthorStyles>
  );
};

export default Author;

import * as React from "react";
import styled from "styled-components";

const PostCategoryStyles = styled.span`
  svg {
    --size: 30px;
    display: grid;
    align-items: center;
    width: var(--size);
    height: var(--size);
    margin: 0 10px;
    color: var(--highlight-dark-theme);
  }
`;

const PostCategory = ({ category }) => (
  <PostCategoryStyles>
    <p>{category.title}</p>
  </PostCategoryStyles>
);

export default PostCategory;
